// ==========[ FONTS ]==========

// ==========[ COLORS ]==========

$white      : #FFFFFF;
$white-light      : #ffffff5b;
$grey-light : #F8F8F8;
$grey       : #E5E5E5;
$grey-dark  : #666666;
$black      : #222222;
$primary    : #1e87f0;

$danger-color  : #f0506e;
$success-color : #32d296;
$warning-color : #faa05a;

$cookie-button-color  : $white;
$cookie-button-bgcolor: $primary;
$cookie-link-color    : $black;