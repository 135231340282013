// ==========[ COMING SOON PAGE ]==========

.cs-wrapper {
  display         : flex;
  width           : 100vw;
  height          : 100vh;
  align-items     : center;
  justify-content : center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .cs-inner {
    max-width     : 500px;
    padding-right : 32px;
    padding-left  : 32px;
    
    .cs-card {
      background-color : $white;
    }
  }

  .cs-title {
    color     : $black;
    font-size : 24px;
  }

  .cs-text {
    color       : transparentize($black, .25);
    font-family : sans-serif;
    font-size   : 16px;
    line-height : 28px;
  }

  .cs-socials {
    display         : flex;
    align-items     : center;
    justify-content : flex-start;

    a {
      margin    : 0px 4px;
      color     : transparentize($black, .35);
      font-size : 18px;

      &:hover {
        color           : $black;
        text-decoration : none;
      }
    }
  }
}
