// ==========[ GENERAL ]==========

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
main#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html,
body {
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

body {
  position: relative;
}

header {
  z-index: 9999;
  top: 0;
  width: 100%;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999999;
}

// ==========[ LAZYSIZES ]==========

// lazysizes
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper {
  position: relative;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

// TER INFO: bijbehorende HTML:
// Voor img tag:
// <div class="lazyload-wrapper" style="padding-bottom: <?= getImageRatio($page->image) ?>%;">
//   <img data-src="<?= $page->image->size(1600, 1600, ['cropping' => false])->url ?>" alt="<?= $page->image->description ?>" class="lazyload">
// </div>
// Voor backgroundimage:
// <div class="lazyload" data-bgset="<?= $page->image->size(2400, 2400, ['cropping' => false])->url ?>"></div>

// ==========[ TEXT / FONTS ]==========

.uk-h1,
h1 {
  color: $black;
  font-size: 38px;
  font-weight: 300;
  line-height: 45.6px;
  font-family: 'Montserrat', sans-serif;
}

.uk-h2,
h2 {
  color: $black;
  font-size: 30px;
  font-weight: 300;
  line-height: 39px;
  font-family: 'Montserrat', sans-serif;
}

.uk-h3,
h3 {
  color: $black;
  font-size: 24px;
  font-weight: 300;
  line-height: 33.6px;
  font-family: 'Montserrat', sans-serif;
}

.uk-h4,
h4 {}

.uk-h5,
h5 {}

.cms,
p {
  color: $grey-dark;
  font-size: 15px;
  font-weight: normal;
  line-height: 22.5px;
  font-family: 'Montserrat', sans-serif;
}

a {
  font-family: 'Montserrat', sans-serif;
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// ==========[ Banner ]==========
.top-banner {
  background-color: $primary;

  &--title {
    color: $white;
  }

  &--text {
    color: $white;

    p {
      color: $white;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

// ==========[ Popup ]==========
#popup-modal {

  .popup {
    &--image {
      display: block;
      position: relative;
      width: 100%;
      padding-top: 75%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

// ==========[ Header ]==========
header {
  height: 140px;
  display: flex;
  align-items: center;

  .uk-navbar-dropdown {
    width: auto;
  }

  .uk-container {
    width: 100%;


    .uk-navbar-container {
      .uk-logo {
        img {
          max-height: 40px;
        }
      }

      .uk-navbar-right {
        li {
          &>a {
            color: $black;
            font-family: 'Montserrat', sans-serif;
            position: relative;
            padding: 0;
            margin: 0 15px;

            &:after {
              background: none repeat scroll 0 0 transparent;
              bottom: 20px;
              content: "";
              display: block;
              height: 2px;
              left: 50%;

              position: absolute;
              background: #fff;
              transition: width 0.3s ease 0s, left 0.3s ease 0s;
              width: 0;
            }

            &:hover {
              &:after {
                width: 100%;
                left: 0;
              }
            }
          }

          &.uk-active {
            a {
              color: black;
              text-decoration: underline;
              text-underline-offset: 10px;
            }
          }
        }
      }
    }

    .uk-navbar-toggle {
      height: 50px;
    }
  }



  &.header-light {
    position: absolute;

    .uk-navbar-dropdown {
      ul>li {
        &.uk-active {
          a {
            text-decoration: none !important;
          }
        }

        &>a {
          color: $black !important;

        }
      }
    }

    .uk-container {
      width: 100%;


      .uk-navbar-container {
        .uk-logo {
          img {
            max-height: 40px;
          }
        }

        .uk-navbar-right {
          li>a {
            color: $white;
            font-family: 'Montserrat', sans-serif;
            position: relative;
            padding: 0;
            margin: 0 15px;

            &:after {
              background: none repeat scroll 0 0 transparent;
              bottom: 20px;
              content: "";
              display: block;
              height: 2px;
              left: 50%;

              position: absolute;
              background: #fff;
              transition: width 0.3s ease 0s, left 0.3s ease 0s;
              width: 0;
            }

            &:hover {
              &:after {
                width: 100%;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  .uk-offcanvas-bar {
    .uk-close {
      display: flex;
      justify-content: end;
      margin-top: 30px;
      margin-right: 30px;
    }

    .toggle {
      font-size: 1rem;
      margin-top: 15px;

      a,
      i {
        color: #999;
      }
    }

    ul.uk-nav {
      &.first {
        margin-top: 10vh;

      }

      .uk-parent {
        margin-bottom: 30px;
      }
    }
  }
}


// ==========[ HOME ]==========
.home-banner {
  height: 100vh;
  padding: 0;

  &-items {
    height: 100%;
    width: 100vw;

    .home-banner-item {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-content {
        border: .5px solid $white-light;
        z-index: 999;
        padding: 50px;
        // transition: .5s;


        h1 {
          font-weight: 500;
          color: $white;
        }

        p {
          color: $white;
          margin: 0;
        }
      }


      &-overlay {
        transition: .8s;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, .40), rgba(0, 0, 0, .40));
        top: 0;
        left: 0;
      }

      &:hover {
        text-decoration: none;

        .home-banner-item-overlay {
          cursor: pointer;
          opacity: .5;
        }

      }

      &:hover {
        .home-banner-item-content {
          border: 1.5px solid $white-light;
          padding: 49px;
        }
      }
    }
  }


  &-content {

    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: $white;
      font-size: 80px;
      line-height: 90px
    }
  }

  .scroll {
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0);

    i {
      font-size: 20px;
      color: $white;
      animation-iteration-count: infinite;
      animation-name: bounce-2;
      animation-duration: 1.2s;
      animation-timing-function: ease;
    }

    @keyframes bounce-2 {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-10px);
      }

      100% {
        transform: translateY(0);
      }
    }
  }
}


// ==========[ Category ]==========
.cat {
  min-height: 800px;

  &-content {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 14px -6px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 0px 14px -6px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 0px 14px -6px rgba(0, 0, 0, 0.37);
    height: 400px;
    margin-right: -100px;
    z-index: 5;

    &-left {
      margin-right: 0;
      margin-left: -100px;
    }

    &-container {
      z-index: 5;
      margin-top: -50px;
    }
  }
}


.cat-thumbnail {
  height: 800px;
}

.category-banner {
  height: 350px;
  padding: 0;

  h2 {
    color: $white;
    font-weight: 400;

  }

  &-overlay {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, transparent, $black);
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    padding-left: 20px;
  }
}

.uk-subnav {
  &-pill {
    a {
      display: block;
      position: relative;
      padding: 0;

      &:first-child {
        padding-left: 0;
        padding-right: 0;
        margin-left: 10px;
        margin-right: 10px;
      }

    }

    /* Fade in */
    a::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: #999;
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    a:hover::after,
    a:focus::after {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
    }

    /* Slide in */
    li a {
      overflow: hidden;
    }

    li a::after {
      opacity: 1;
      transform: translate3d(-100%, 0, 0);
    }

    li a:hover::after,
    li a:focus::after {
      transform: translate3d(0, 0, 0);
    }

    a {
      color: $black;
      transition: .2s;

      &:hover {
        background-color: transparent;
      }
    }

    .uk-active {
      a {
        background-color: transparent;
        text-decoration: underline;
        text-underline-offset: 10px;
        color: $black;
      }
    }
  }
}

.owners {
  img {
    width: 100%;
  }
}

.conditions {
  &-item {
    &-image {
      &_container {
        height: 200px;
        display: flex;
        align-items: center;
      }

      display: flex;
      justify-content: center;

      img {
        width: 50%;
      }
    }
  }
}




// ==========[ Footer ]==========
.push-footer {
  flex-grow: 1;
}

.parallax {
  /* The image used */

  /* Set a specific height */
  // min-height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &-overlay {
    background-color: rgba(0, 0, 0, .6);
    opacity: 0;
    transition: .5s;
    height: 100%;

    .content {
      background-color: $white;
      z-index: 1;

      h2,
      p,
      li {
        color: $white;
      }

    }

    &:hover {
      opacity: 1;

      .content {
        opacity: 1;
      }
    }
  }
}

.uk-sticky-fixed {
  z-index: 0 !important;
}

.uk-sticky {
  z-index: 1 !important;
}

.content {
  background-color: $white;
  z-index: 1;
}

// ==========[ TABLE ]==========
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;

  thead {
    background-color: lightgrey;
  }

  th,
  td {
    width: 25%;
  }

  th {
    text-align: center;
    padding: 10px 0px;
  }


  td {
    &>.price {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 0;
    }

    &.months {
      text-align: center;
    }
  }
}

.price-container {
  h3 {
    font-weight: 500;
  }

  margin-bottom: 40px;

  table {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-top: 0.5px solid lightgray;
    border-bottom: 0.5px solid lightgray;

    th {
      border-right: .5px solid lightgray;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.calendar {
  --side-padding: 20px; // padding to be applied to the side of the calendar
  --border-radius: 34px; // border-radius of the main calendar shell
  --accent-br: 15px; // border-radius for accents under dates on hover
  border-right: .5px solid lightgray;
  border-bottom: .5px solid lightgray;
  padding-bottom: 30px;

  &:nth-child(3n+3) {
    border-right: none;
  }

  &:nth-last-child(-n+3) {
    border-bottom: none;
  }

  select {
    background-color: #f3f4f6;
    padding: 15px 20px;
  }

  // &__opts,
  // &__buttons {
  //   background-color: #fff;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   column-gap: 15px;
  // }

  &__opts {
    .month {
      text-align: center;
      margin-bottom: 25px;
      margin-top: 25px;
      font-weight: 500;
    }
  }

  &__body {
    background-image: linear-gradient(to bottom, #f3f4f6, #fff);
  }

  &__days {
    background-color: #fff;
    padding: 0 var(--side-padding) 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    &>div {
      text-align: center;

      font: {
        weight: 700;
        size: 1.02rem;
      }

      color: #c5c8ca;
    }
  }

  &__dates {
    padding: 10px var(--side-padding);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  &__date {
    --height: calc(400px / 6 - var(--side-padding));

    text-align: center;
    height: var(--height);
    line-height: var(--height);

    font: {
      weight: 600;
      size: 1.02rem;
    }

    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-color: rgba(255, 255, 255, 0);
      width: 100%;
      height: calc(var(--height) * 0.9);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: var(--accent-br);
      transition: background-color 0.3s ease;
    }

    &:not(.calendar__date--booked):not(.calendar__date--grey):hover::before {
      background-color: #ededed;
    }

    &--grey {
      color: #c5c8ca;
      cursor: not-allowed;
    }

    &--booked {
      color: #ff374b;

      &::before {
        background-color: #ffeaec;
        border-radius: 0px;
      }

      &-half {
        // background: rgba(248, 80, 50, 1);
        color: #ff374b;

        &::before {
          background: linear-gradient(to bottom right, #ffeaec 50%, #c3f8df 50%);
          border-radius: 0px;
        }

        &-available {
          color: #ff374b;

          &::before {
            background: linear-gradient(to top left, #ffeaec 50%, #c3f8df 50%);
            border-radius: 0px;
          }
        }
      }
    }

    &--available {
      color: #30a962;

      &::before {
        background-color: #c3f8df;
        border-radius: 0px;
      }
    }

    // &--range-start {
    //   &::after {
    //     content: "";
    //     position: absolute;
    //     bottom: 3px;
    //     border-radius: 24px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     background-color: #ff374b;
    //     width: 10px;
    //     height: 4px;
    //   }
    // }

    // &--range-end {
    //   color: #fff;

    //   &::before {
    //     box-shadow: 0 15px 20px -3px rgba(255, 55, 75, 0.35);
    //     background-color: #ff374b;
    //     border-radius: var(--accent-br);
    //     z-index: 1;
    //   }

    //   &::after {
    //     content: "";
    //     position: absolute;
    //     height: calc(var(--height) * 0.9);
    //     background-color: #ffeaec;
    //     width: 50px;
    //     top: 50%;
    //     right: 50%;
    //     transform: translateY(-50%);
    //   }
    // }

    span {
      position: relative;
      z-index: 1;
    }
  }

  &__buttons {
    padding: 10px var(--side-padding) 20px;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  &__button {
    cursor: pointer;

    &--grey {
      background-color: #f3f4f6;
    }

    &--primary {
      background-color: #1752ff;
      color: #fff;
      transition: box-shadow 0.3s cubic-bezier(0.21, 0.68, 0.09, 0.27),
        transform 0.2s linear;

      &:hover {
        box-shadow: 0 20px 30px -13px rgba(23, 82, 255, 0.45);
        transform: translateY(-3px);
      }

      &:active {
        box-shadow: 0 10px 10px -6px rgba(23, 82, 255, 0.45);
        transform: translateY(-1px);
      }
    }
  }
}

select,
button {
  font: {
    family: inherit;
    weight: 700;
    size: 1.02rem;
  }

  border-radius: 20px;
  outline: none;
  border: 0;
  padding: 15px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  background: {
    image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='960px' height='560px' viewBox='0 0 960 560' enable-background='new 0 0 960 560' xml:space='preserve'%3E%3Cg id='Rounded_Rectangle_33_copy_4_1_'%3E%3Cpath d='M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z'/%3E%3C/g%3E%3C/svg%3E");
    size: 24px;
    repeat: no-repeat;
    position: calc(100% - var(--side-padding)) center;
  }
}

.map {
  iframe {
    width: 100%;
    height: 400px;
  }
}

.contact-links {
  a {}
}