form .uk-form-label,
.form-custom .uk-form-label {
  color: #222222;
  font-size: 16px;
  font-weight: 400;
}
form .uk-form-label.uk-form-danger,
.form-custom .uk-form-label.uk-form-danger {
  color: #f0506e;
}
form .form-url,
.form-custom .form-url {
  display: none;
}
form .uk-checkbox.uk-form-danger:not(:checked),
.form-custom .uk-checkbox.uk-form-danger:not(:checked) {
  background-color: rgba(240, 80, 110, 0.08);
  box-shadow: inset 0 0 0 1px #f0506e;
}
form .uk-input,
form .uk-select,
form .uk-textarea,
.form-custom .uk-input,
.form-custom .uk-select,
.form-custom .uk-textarea {
  min-height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #E5E5E5;
  font-size: 16px;
}
form .uk-input:focus,
form .uk-select:focus,
form .uk-textarea:focus,
.form-custom .uk-input:focus,
.form-custom .uk-select:focus,
.form-custom .uk-textarea:focus {
  box-shadow: inset 0 0 0 1px #666666;
}
form .uk-input:disabled,
form .uk-select:disabled,
form .uk-textarea:disabled,
.form-custom .uk-input:disabled,
.form-custom .uk-select:disabled,
.form-custom .uk-textarea:disabled {
  background-color: #F8F8F8;
}
form .uk-input.uk-form-danger,
form .uk-select.uk-form-danger,
form .uk-textarea.uk-form-danger,
.form-custom .uk-input.uk-form-danger,
.form-custom .uk-select.uk-form-danger,
.form-custom .uk-textarea.uk-form-danger {
  color: #f0506e;
  background-color: rgba(240, 80, 110, 0.08);
  box-shadow: inset 0 0 0 1px #f0506e;
}
form .uk-input.uk-form-success,
form .uk-select.uk-form-success,
form .uk-textarea.uk-form-success,
.form-custom .uk-input.uk-form-success,
.form-custom .uk-select.uk-form-success,
.form-custom .uk-textarea.uk-form-success {
  color: #32d296;
  background-color: rgba(50, 210, 150, 0.08);
  box-shadow: inset 0 0 0 1px #32d296;
}
form .uk-textarea,
.form-custom .uk-textarea {
  padding: 20px;
}

.uk-button {
  padding: 10px 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  transition: 0.2s;
}
.uk-button-primary {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  z-index: 999;
}
.uk-button-primary:hover {
  background-color: #FFFFFF;
  color: #222222;
}
.uk-button-primary-contact {
  border: 1px solid #E5E5E5;
  color: #666666;
}
.uk-button-default {
  color: #222222;
  border: 1px solid #E5E5E5;
  background-color: transparent;
}

.uk-button-link {
  padding-right: 0;
  padding-left: 0;
  color: #222222;
}

.full-left-image,
.full-right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-left-image {
  right: 50%;
  left: 0;
}

.full-right-image {
  right: 0;
  left: 50%;
}

.image-block {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.image-block.square {
  padding-top: 100%;
}
.image-block.long {
  padding-top: 133%;
}

.button-icon {
  display: flex;
  width: 60px;
  height: 60px;
  color: #666666;
  border-radius: 999px;
  background-color: #FFFFFF;
  align-items: center;
  font-size: 21px;
  justify-content: center;
}

/**
 * |==========================================================================|
 * | UIKIT SWITCH
 * |==========================================================================|
 */
.uk-switch {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 40px;
}

.uk-switch input {
  display: none;
}

.uk-switch-slider {
  background-color: rgba(0, 0, 0, 0.22);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 500px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.2s;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
}

.uk-switch-slider:before {
  content: "";
  background-color: #fff;
  position: absolute;
  width: 22px;
  height: 22px;
  left: 0px;
  bottom: 0px;
  border-radius: 50%;
  transition-property: transform, box-shadow;
  transition-duration: 0.2s;
}

.uk-switch-disabled {
  cursor: initial;
}

input:checked + .uk-switch-slider:not(.uk-switch-disabled) {
  background-color: #39f !important;
}

input:checked + .uk-switch-slider:before {
  transform: translateX(18px);
}

.uk-switch-slider.uk-switch-on-off {
  background-color: #f0506e;
}

input:checked + .uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important;
}

.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

input:checked + .uk-switch-slider.uk-switch-big:before {
  transform: translateX(26px) scale(1.2);
}

.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255, 255, 255, 0.22);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
main#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html,
body {
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

body {
  position: relative;
}

header {
  z-index: 9999;
  top: 0;
  width: 100%;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999999;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper {
  position: relative;
}
.lazyload-wrapper img {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.uk-h1,
h1 {
  color: #222222;
  font-size: 38px;
  font-weight: 300;
  line-height: 45.6px;
  font-family: "Montserrat", sans-serif;
}

.uk-h2,
h2 {
  color: #222222;
  font-size: 30px;
  font-weight: 300;
  line-height: 39px;
  font-family: "Montserrat", sans-serif;
}

.uk-h3,
h3 {
  color: #222222;
  font-size: 24px;
  font-weight: 300;
  line-height: 33.6px;
  font-family: "Montserrat", sans-serif;
}

.cms,
p {
  color: #666666;
  font-size: 15px;
  font-weight: normal;
  line-height: 22.5px;
  font-family: "Montserrat", sans-serif;
}

a {
  font-family: "Montserrat", sans-serif;
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.top-banner {
  background-color: #1e87f0;
}
.top-banner--title {
  color: #FFFFFF;
}
.top-banner--text {
  color: #FFFFFF;
}
.top-banner--text p {
  color: #FFFFFF;
}
.top-banner--text p:last-of-type {
  margin-bottom: 0;
}

#popup-modal .popup--image {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

header {
  height: 140px;
  display: flex;
  align-items: center;
}
header .uk-navbar-dropdown {
  width: auto;
}
header .uk-container {
  width: 100%;
}
header .uk-container .uk-navbar-container .uk-logo img {
  max-height: 40px;
}
header .uk-container .uk-navbar-container .uk-navbar-right li > a {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  position: relative;
  padding: 0;
  margin: 0 15px;
}
header .uk-container .uk-navbar-container .uk-navbar-right li > a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 20px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
header .uk-container .uk-navbar-container .uk-navbar-right li > a:hover:after {
  width: 100%;
  left: 0;
}
header .uk-container .uk-navbar-container .uk-navbar-right li.uk-active a {
  color: black;
  text-decoration: underline;
  text-underline-offset: 10px;
}
header .uk-container .uk-navbar-toggle {
  height: 50px;
}
header.header-light {
  position: absolute;
}
header.header-light .uk-navbar-dropdown ul > li.uk-active a {
  text-decoration: none !important;
}
header.header-light .uk-navbar-dropdown ul > li > a {
  color: #222222 !important;
}
header.header-light .uk-container {
  width: 100%;
}
header.header-light .uk-container .uk-navbar-container .uk-logo img {
  max-height: 40px;
}
header.header-light .uk-container .uk-navbar-container .uk-navbar-right li > a {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  position: relative;
  padding: 0;
  margin: 0 15px;
}
header.header-light .uk-container .uk-navbar-container .uk-navbar-right li > a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 20px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
header.header-light .uk-container .uk-navbar-container .uk-navbar-right li > a:hover:after {
  width: 100%;
  left: 0;
}
header .uk-offcanvas-bar .uk-close {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  margin-right: 30px;
}
header .uk-offcanvas-bar .toggle {
  font-size: 1rem;
  margin-top: 15px;
}
header .uk-offcanvas-bar .toggle a,
header .uk-offcanvas-bar .toggle i {
  color: #999;
}
header .uk-offcanvas-bar ul.uk-nav.first {
  margin-top: 10vh;
}
header .uk-offcanvas-bar ul.uk-nav .uk-parent {
  margin-bottom: 30px;
}

.home-banner {
  height: 100vh;
  padding: 0;
}
.home-banner-items {
  height: 100%;
  width: 100vw;
}
.home-banner-items .home-banner-item {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-banner-items .home-banner-item-content {
  border: 0.5px solid rgba(255, 255, 255, 0.3568627451);
  z-index: 999;
  padding: 50px;
}
.home-banner-items .home-banner-item-content h1 {
  font-weight: 500;
  color: #FFFFFF;
}
.home-banner-items .home-banner-item-content p {
  color: #FFFFFF;
  margin: 0;
}
.home-banner-items .home-banner-item-overlay {
  transition: 0.8s;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  top: 0;
  left: 0;
}
.home-banner-items .home-banner-item:hover {
  text-decoration: none;
}
.home-banner-items .home-banner-item:hover .home-banner-item-overlay {
  cursor: pointer;
  opacity: 0.5;
}
.home-banner-items .home-banner-item:hover .home-banner-item-content {
  border: 1.5px solid rgba(255, 255, 255, 0.3568627451);
  padding: 49px;
}
.home-banner-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner-content h1 {
  color: #FFFFFF;
  font-size: 80px;
  line-height: 90px;
}
.home-banner .scroll {
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, 0);
}
.home-banner .scroll i {
  font-size: 20px;
  color: #FFFFFF;
  animation-iteration-count: infinite;
  animation-name: bounce-2;
  animation-duration: 1.2s;
  animation-timing-function: ease;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.cat {
  min-height: 800px;
}
.cat-content {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 14px -6px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 14px -6px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 14px -6px rgba(0, 0, 0, 0.37);
  height: 400px;
  margin-right: -100px;
  z-index: 5;
}
.cat-content-left {
  margin-right: 0;
  margin-left: -100px;
}
.cat-content-container {
  z-index: 5;
  margin-top: -50px;
}

.cat-thumbnail {
  height: 800px;
}

.category-banner {
  height: 350px;
  padding: 0;
}
.category-banner h2 {
  color: #FFFFFF;
  font-weight: 400;
}
.category-banner-overlay {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent, #222222);
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-left: 20px;
}

.uk-subnav-pill {
  /* Fade in */
  /* Slide in */
}
.uk-subnav-pill a {
  display: block;
  position: relative;
  padding: 0;
}
.uk-subnav-pill a:first-child {
  padding-left: 0;
  padding-right: 0;
  margin-left: 10px;
  margin-right: 10px;
}
.uk-subnav-pill a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #999;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
.uk-subnav-pill a:hover::after,
.uk-subnav-pill a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}
.uk-subnav-pill li a {
  overflow: hidden;
}
.uk-subnav-pill li a::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}
.uk-subnav-pill li a:hover::after,
.uk-subnav-pill li a:focus::after {
  transform: translate3d(0, 0, 0);
}
.uk-subnav-pill a {
  color: #222222;
  transition: 0.2s;
}
.uk-subnav-pill a:hover {
  background-color: transparent;
}
.uk-subnav-pill .uk-active a {
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 10px;
  color: #222222;
}

.owners img {
  width: 100%;
}

.conditions-item-image {
  display: flex;
  justify-content: center;
}
.conditions-item-image_container {
  height: 200px;
  display: flex;
  align-items: center;
}
.conditions-item-image img {
  width: 50%;
}

.push-footer {
  flex-grow: 1;
}

.parallax {
  /* The image used */
  /* Set a specific height */
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: 0.5s;
  height: 100%;
}
.parallax-overlay .content {
  background-color: #FFFFFF;
  z-index: 1;
}
.parallax-overlay .content h2,
.parallax-overlay .content p,
.parallax-overlay .content li {
  color: #FFFFFF;
}
.parallax-overlay:hover {
  opacity: 1;
}
.parallax-overlay:hover .content {
  opacity: 1;
}

.uk-sticky-fixed {
  z-index: 0 !important;
}

.uk-sticky {
  z-index: 1 !important;
}

.content {
  background-color: #FFFFFF;
  z-index: 1;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
}
table thead {
  background-color: lightgrey;
}
table th,
table td {
  width: 25%;
}
table th {
  text-align: center;
  padding: 10px 0px;
}
table td > .price {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
table td.months {
  text-align: center;
}

.price-container {
  margin-bottom: 40px;
}
.price-container h3 {
  font-weight: 500;
}
.price-container table {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-top: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
}
.price-container table th {
  border-right: 0.5px solid lightgray;
}
.price-container:last-child {
  margin-bottom: 0;
}

.calendar {
  --side-padding: 20px;
  --border-radius: 34px;
  --accent-br: 15px;
  border-right: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
  padding-bottom: 30px;
}
.calendar:nth-child(3n+3) {
  border-right: none;
}
.calendar:nth-last-child(-n+3) {
  border-bottom: none;
}
.calendar select {
  background-color: #f3f4f6;
  padding: 15px 20px;
}
.calendar__opts .month {
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  font-weight: 500;
}
.calendar__body {
  background-image: linear-gradient(to bottom, #f3f4f6, #fff);
}
.calendar__days {
  background-color: #fff;
  padding: 0 var(--side-padding) 10px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calendar__days > div {
  text-align: center;
  font-weight: 700;
  font-size: 1.02rem;
  color: #c5c8ca;
}
.calendar__dates {
  padding: 10px var(--side-padding);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calendar__date {
  --height: calc(400px / 6 - var(--side-padding));
  text-align: center;
  height: var(--height);
  line-height: var(--height);
  font-weight: 600;
  font-size: 1.02rem;
  cursor: pointer;
  position: relative;
}
.calendar__date::before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: calc(var(--height) * 0.9);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--accent-br);
  transition: background-color 0.3s ease;
}
.calendar__date:not(.calendar__date--booked):not(.calendar__date--grey):hover::before {
  background-color: #ededed;
}
.calendar__date--grey {
  color: #c5c8ca;
  cursor: not-allowed;
}
.calendar__date--booked {
  color: #ff374b;
}
.calendar__date--booked::before {
  background-color: #ffeaec;
  border-radius: 0px;
}
.calendar__date--booked-half {
  color: #ff374b;
}
.calendar__date--booked-half::before {
  background: linear-gradient(to bottom right, #ffeaec 50%, #c3f8df 50%);
  border-radius: 0px;
}
.calendar__date--booked-half-available {
  color: #ff374b;
}
.calendar__date--booked-half-available::before {
  background: linear-gradient(to top left, #ffeaec 50%, #c3f8df 50%);
  border-radius: 0px;
}
.calendar__date--available {
  color: #30a962;
}
.calendar__date--available::before {
  background-color: #c3f8df;
  border-radius: 0px;
}
.calendar__date span {
  position: relative;
  z-index: 1;
}
.calendar__buttons {
  padding: 10px var(--side-padding) 20px;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.calendar__button {
  cursor: pointer;
}
.calendar__button--grey {
  background-color: #f3f4f6;
}
.calendar__button--primary {
  background-color: #1752ff;
  color: #fff;
  transition: box-shadow 0.3s cubic-bezier(0.21, 0.68, 0.09, 0.27), transform 0.2s linear;
}
.calendar__button--primary:hover {
  box-shadow: 0 20px 30px -13px rgba(23, 82, 255, 0.45);
  transform: translateY(-3px);
}
.calendar__button--primary:active {
  box-shadow: 0 10px 10px -6px rgba(23, 82, 255, 0.45);
  transform: translateY(-1px);
}

select,
button {
  font-family: inherit;
  font-weight: 700;
  font-size: 1.02rem;
  border-radius: 20px;
  outline: none;
  border: 0;
  padding: 15px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='960px' height='560px' viewBox='0 0 960 560' enable-background='new 0 0 960 560' xml:space='preserve'%3E%3Cg id='Rounded_Rectangle_33_copy_4_1_'%3E%3Cpath d='M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - var(--side-padding)) center;
}

.map iframe {
  width: 100%;
  height: 400px;
}

.cs-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.cs-wrapper .cs-inner {
  max-width: 500px;
  padding-right: 32px;
  padding-left: 32px;
}
.cs-wrapper .cs-inner .cs-card {
  background-color: #FFFFFF;
}
.cs-wrapper .cs-title {
  color: #222222;
  font-size: 24px;
}
.cs-wrapper .cs-text {
  color: rgba(34, 34, 34, 0.75);
  font-family: sans-serif;
  font-size: 16px;
  line-height: 28px;
}
.cs-wrapper .cs-socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cs-wrapper .cs-socials a {
  margin: 0px 4px;
  color: rgba(34, 34, 34, 0.65);
  font-size: 18px;
}
.cs-wrapper .cs-socials a:hover {
  color: #222222;
  text-decoration: none;
}

.wrapper-404 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("/dist/assets/images/404/404-bg.gif");
  background-position: center;
  background-size: cover;
}
.wrapper-404 .overlay-404 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, rgba(116, 235, 213, 0.67) 0%, rgba(172, 182, 229, 0.67) 100%);
  align-items: center;
  justify-content: center;
}
.wrapper-404 .overlay-404 .inner-404 {
  max-width: 500px;
  padding: 50px;
  border-radius: 4px;
  background-color: #FFFFFF;
}
.wrapper-404 .overlay-404 .inner-404 h1 {
  margin-bottom: 15px;
  background: linear-gradient(135deg, rgba(116, 235, 213, 0.67) 0%, rgba(172, 182, 229, 0.67) 100%);
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 800;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wrapper-404 .overlay-404 .inner-404 h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 400;
}
.wrapper-404 .overlay-404 .inner-404 p {
  margin-bottom: 0;
}
.wrapper-404 .overlay-404 .inner-404 p a {
  color: rgb(116, 235, 213);
}
.wrapper-404 .overlay-404 .inner-404 p a:hover {
  color: #31e1c1;
}

.edit__page__button {
  z-index: 9999;
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  color: #FFFFFF;
  border-radius: 999px;
  background: linear-gradient(135deg, rgb(116, 235, 213) 0%, rgb(172, 182, 229) 100%);
  align-items: center;
  justify-content: center;
  transition: all 350ms cubic-bezier(0.77, 0, 0.175, 1);
}
.edit__page__button:hover {
  color: #FFFFFF;
  transform: scale(1.15);
}

.cookie-banner {
  box-shadow: 0px 3px 15px rgba(34, 34, 34, 0.2);
  z-index: 10;
}
.cookie-banner p {
  font-size: 14px;
  line-height: 125%;
}
.cookie-banner p a {
  color: #222222;
  text-decoration: underline;
}
.cookie-banner p a:hover {
  color: black;
}
.cookie-banner .button-wrap {
  min-width: 30%;
}
.cookie-banner .button {
  margin: 2px;
  margin-left: 12px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.cookie-banner .uk-button-primary {
  background-color: #1e87f0;
  color: #FFFFFF;
}
.cookie-banner .uk-button-primary:hover {
  background-color: #0f7ae5;
}
.cookie-banner #cookie_form {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 24px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.cookie-banner #cookie_form .form-url {
  display: none;
}
.cookie-banner #cookie_form .cookie-banner-popup {
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(34, 34, 34, 0.2);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 48px);
  max-width: 860px;
  width: 100%;
}
.cookie-banner #cookie_form .cookie-banner-popup__bottom {
  border-top: 1px solid #dddddd;
  padding: 10px 20px;
}
.cookie-banner #cookie_form .cookie-banner-popup__top {
  overflow: auto;
  padding: 20px;
  flex: 1;
}

@media only screen and (max-width: 961px) {
  .cookie-banner {
    text-align: center;
  }
  .cookie-banner p {
    font-size: 12px;
    text-align: center !important;
  }
  .cookie-banner .button {
    font-size: 12px;
  }
}
@media (max-width: 960px) {
  .full-left-image,
.full-right-image {
    position: relative;
    right: 0;
    left: 0;
    height: 400px;
    margin-right: 30px;
    margin-left: 30px;
  }
  header {
    height: 90px;
  }
  .calendar:nth-child(3n+3) {
    border-right: 0.5px solid lightgray;
  }
  .calendar:nth-child(2n+2) {
    border-right: none;
  }
  .calendar:nth-last-child(-n+3) {
    border-bottom: 0.5px solid lightgray;
  }
  .home-banner-items {
    flex-direction: column;
  }
  .home-banner-items .home-banner-item {
    width: 100%;
  }
  .home-banner-items .home-banner-item:first-child {
    align-items: flex-end;
    padding-bottom: 35px;
  }
  .home-banner-items .home-banner-item-content {
    padding: 20px;
    width: 75%;
  }
  .home-banner-items .home-banner-item-content p {
    font-size: 12px;
  }
  .home-banner-items .home-banner-item-content h1 {
    font-size: 21px;
    font-weight: 400;
  }
  .home-banner-items .home-banner-item:hover .home-banner-item-content {
    border: 1.5px solid rgba(255, 255, 255, 0.3568627451);
    padding: 19px;
  }
  .category-banner {
    height: 250px;
  }
}
@media (max-width: 640px) {
  header .uk-container .uk-navbar-container .uk-logo img {
    max-height: 22px;
  }
  .uk-subnav {
    margin-left: 0;
  }
  .uk-subnav > * {
    padding-left: 0;
    margin-bottom: 10px;
  }
  .lazyload-wrapper {
    padding-bottom: 0 !important;
  }
  .lazyload-wrapper img {
    position: relative;
  }
  .uk-subnav-pill a:first-child {
    margin-left: 15px;
    margin-right: 5px;
  }
  .calendar > div {
    border-right: 0;
  }
  .calendar:nth-child(n+1) {
    border-right: none;
  }
  .calendar:nth-child(1n+1) {
    border-right: none;
  }
  .uk-h1,
h1 {
    font-size: 30px;
    line-height: 39px;
  }
  .uk-h2,
h2 {
    font-size: 24px;
    line-height: 33.6px;
  }
  .uk-h3,
h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .conditions-item-image img {
    width: 60%;
  }
  .category-banner {
    height: 200px;
  }
}
@media (max-height: 700px) {
  .home-banner {
    height: 125vh;
  }
  header .uk-offcanvas-bar ul.uk-nav.first {
    margin-top: 7vh;
  }
}