// ==========[ FORMS ]==========

form,
.form-custom {
  .uk-form-label {
    color: $black;
    font-size: 16px;
    font-weight: 400;

    &.uk-form-danger {
      color: $danger-color;
    }
  }

  .form-url {
    display: none;
  }

  .uk-checkbox {
    &.uk-form-danger {
      &:not(:checked) {
        background-color: transparentize($danger-color, .92);
        box-shadow: inset 0 0 0 1px $danger-color;
      }
    }
  }

  .uk-input,
  .uk-select,
  .uk-textarea {
    min-height: 50px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $grey;
    font-size: 16px;

    &:focus {
      box-shadow: inset 0 0 0 1px $grey-dark;
    }

    &:disabled {
      background-color: $grey-light;
    }

    &.uk-form-danger {
      color: $danger-color;
      background-color: transparentize($danger-color, .92);
      box-shadow: inset 0 0 0 1px $danger-color;
    }

    &.uk-form-success {
      color: $success-color;
      background-color: transparentize($success-color, .92);
      box-shadow: inset 0 0 0 1px $success-color;
    }
  }

  .uk-textarea {
    padding: 20px;
  }
}

// ==========[ BUTTONS ]==========

.uk-button {
  padding: 10px 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  transition: .2s;

  &-primary {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    z-index: 999;

    &:hover {
      background-color: $white;
      color: $black;
    }

    &-contact {
      border: 1px solid $grey;
      color: #666666;
    }
  }

  &-secondary {}

  &-tertiairy {}
}

.uk-button-default {
  color: $black;
  border: 1px solid $grey;
  background-color: transparent;
}

.uk-button-link {
  padding-right: 0;
  padding-left: 0;
  color: $black;
}

// ==========[ WYSIWYG / PAGE BUILDER ]==========

.full-left-image,
.full-right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-left-image {
  right: 50%;
  left: 0;
}

.full-right-image {
  right: 0;
  left: 50%;
}

// ==========[ IMAGE BLOCKS ]==========

.image-block {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.square {
    padding-top: 100%;
  }

  &.long {
    padding-top: 133%;
  }
}

.button-icon {
  display: flex;
  width: 60px;
  height: 60px;
  color: $grey-dark;
  border-radius: 999px;
  background-color: $white;
  align-items: center;
  font-size: 21px;
  justify-content: center;
}

/**
 * |==========================================================================|
 * | UIKIT SWITCH
 * |==========================================================================|
 */
.uk-switch {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 40px;
}

.uk-switch input {
  display: none;
}

.uk-switch-slider {
  background-color: rgba(0, 0, 0, 0.22);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 500px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .2s;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
}

.uk-switch-slider:before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 22px;
  height: 22px;
  left: 0px;
  bottom: 0px;
  border-radius: 50%;
  transition-property: transform, box-shadow;
  transition-duration: .2s;
}

.uk-switch-disabled {
  cursor: initial;
}

input:checked+.uk-switch-slider {
  &:not(.uk-switch-disabled) {
    background-color: #39f !important;
  }
}

input:checked+.uk-switch-slider:before {
  transform: translateX(18px);
}

.uk-switch-slider.uk-switch-on-off {
  background-color: #f0506e;
}

input:checked+.uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important;
}

.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

input:checked+.uk-switch-slider.uk-switch-big:before {
  transform: translateX(26px) scale(1.2);
}

.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255, 255, 255, 0.22);
}