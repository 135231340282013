// ==========[ VERY LARGE DESKTOPS ]==========

@media (max-width : 1600px) {}

// ==========[ DESKTOPS ]==========

@media (max-width : 1200px) {}

// ==========[ TABLETS ]==========

@media (max-width : 960px) {

  .full-left-image,
  .full-right-image {
    position: relative;
    right: 0;
    left: 0;
    height: 400px;
    margin-right: 30px;
    margin-left: 30px;
  }

  header {
    height: 90px;
  }

  .calendar {
    &:nth-child(3n+3) {
      border-right: .5px solid lightgray;

    }

    &:nth-child(2n+2) {
      border-right: none;
    }

    &:nth-last-child(-n+3) {
      border-bottom: .5px solid lightgray;
    }
  }

  .home-banner-items {
    flex-direction: column;

    .home-banner-item {
      width: 100%;

      &:first-child {
        align-items: flex-end;
        padding-bottom: 35px;
      }

      &-content {
        padding: 20px;
        width: 75%;

        p {
          font-size: 12px;
        }

        h1 {
          font-size: 21px;
          font-weight: 400;
        }
      }

      &:hover {
        .home-banner-item-content {
          border: 1.5px solid $white-light;
          padding: 19px;
        }
      }
    }
  }

  .category-banner {
    height: 250px;
  }
}

// ==========[ MOBILE DEVICES ]==========

@media (max-width : 640px) {
  header .uk-container .uk-navbar-container .uk-logo img {
    max-height: 22px;
  }

  .uk-subnav {
    margin-left: 0;

    &>* {
      padding-left: 0;
      margin-bottom: 10px;
    }
  }

  .lazyload-wrapper {
    padding-bottom: 0 !important;

    img {
      position: relative;
    }
  }

  .uk-subnav-pill a:first-child {
    margin-left: 15px;
    margin-right: 5px;
  }

  .calendar {

    >div {
      border-right: 0;
    }

    &:nth-child(n+1) {
      border-right: none;

    }

    &:nth-child(1n+1) {
      border-right: none;
    }

  }

  .uk-h1,
  h1 {
    font-size: 30px;
    line-height: 39px;
  }

  .uk-h2,
  h2 {
    font-size: 24px;
    line-height: 33.6px;
  }

  .uk-h3,
  h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .conditions-item-image img {
    width: 60%;
  }

  .category-banner {
    height: 200px;
  }
}

@media (max-height : 700px) {
  .home-banner {
    height: 125vh;
  }

  header .uk-offcanvas-bar ul.uk-nav.first {
    margin-top: 7vh;
  }
}